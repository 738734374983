.paymentt{
    width: 40%;
    height: 55vh;
    margin-top: 1.1%;
    margin-left: -15%;
    background-color: transparent;
    transition: 1s;
}

.paymentt:hover{
    background-color: white;
}

.photocontainer{
    width: 40%;
    margin-top: 1%;
    display: flex;
    height: 50vh;
}

.photoleft{
    width: 25%;
}

.photoleft div{
    width: 70%;
    height: 30%;
    margin-left: 25%;
}

.photoleft div img{
    width: 100%;
    height: 100%;
}

.ontview{
    background-color: black;
    cursor: pointer;
}

.leftview{
    background-color: green;
    cursor: pointer;
    margin-top: 5%;
}

.rightview{
    background-color: whitesmoke;
    cursor: pointer;
    margin-top: 5%;
}

.photozoom{
    background-color: whitesmoke;
    width: 72%;
    height: 110%;
    overflow: hidden;
    border: 3px solid #fff;
    position: relative;
    cursor: zoom-in;
}

.photozoom img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    min-width: 100%;
    pointer-events: none;
}



.buywatchcontainer{
    display: flex;
    justify-content: space-around;
}

.buybutton-div{
    width: 100%;
    height: 40%;
    margin-top: 13.4%;
    justify-content: space-around;
    display: flex;
    padding-top: 10%;
}
.il2{
    width: 60%;
    height: 100%;
}

.il2 h1{
    font-size: 150%;
    margin-top: 10%;
    color: white;
    transition: 1s;
}

.paymentsys-button{
    width: 30%;
    height: 100%;

}

.buynow{
    width: 90%;
    height: 90px;
    font-size: 200%;
    margin-left: 6.5%;
    color: black;
    border-color: transparent;
    transition: 0.5s;
}

.paymentt-top{
    padding-top: 5%;
    padding-left: 5%;
    width: 100%;
    height: 40%;
    color: white;
}

.paymentt-top h1{
    transition: 1s;
}

.paymentt-top h6{
    transition: 1s;
}

.paymentsys-button a{
    text-decoration: none;
    color: black;
}

.frontview img{
    background-color: white;
}

@media(max-width:768px){
    .frontview{
        display: none;
    }
    .buywatchcontainer{
        display: flex;
        flex-wrap: wrap;
    }

    .photocontainer{
        width: 100%;
    }
    .photozoom{
        width: 70%;
        height: 60%;
        margin-top: 60px;
        margin-left: -40px;
    }
    .photozoom img{
        width: 100%;
        height: 100%!important;
    }
    .paymentt{
        width: 100%;
        margin-left: 0px;
        height: 50%;
        background-color: transparent;
    }
    .paymentt-top{
        margin-bottom: -100px!important;
    }
    .paymentt h6{
        color: black!important;
    }
    .paymentt h1{
        color: black!important;
    }
    .buynow{
        font-size: 100%;
        width: 80%;
        height: 40px;
        background-color: black;
        color: white;
        margin-top: 10px;
    }
}

