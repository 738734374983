.cardscontainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.searchform{
  width: 30%;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  height: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: translateY(100%);
  transition: transform 0.3s ease;
  margin: auto;
}

.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  width: 100%;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}


.button-32 {
  display: none!important;
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  text-align: center;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  width: 100%;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-32:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-32:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-32:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.searchform2button{
  display: none;
}

.overlay.show {
  opacity: 1;
  pointer-events: all;
}

.popup.show {
  transform: translateY(0%);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}
.pagination .active {
    background-color: #485353a1;
    color: white;
  }

  .p62{
    font-size: 0px!important;
  }

  .mexanizmchange{
    margin-top: 0px;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em, linear-gradient(to left, #8892b0 3em, #ffffff 3em);;
  }

  .korpusselect{
    margin-top: 0px;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em, linear-gradient(to left, #8892b0 3em, #ffffff 3em);;
  }

  .inputmin {
    width: 40%;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    outline: none;
  }
  
  .inputmin:focus {
    background-color: #e0e0e0;
  }
  
  .inputmin::placeholder {
    color: #999;
  }

  .inputmax {
    width: 40%;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    outline: none;
  }
  
  .inputmax:focus {
    background-color: #e0e0e0;
  }
  
  .inputmax::placeholder {
    color: #999;
  }

  .colorchange{
    margin-top: 0px;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em, linear-gradient(to left, #8892b0 3em, #ffffff 3em);;
  }

  .korpusdiv{
    width: 70%!important;
  }
  
  .pricefilteropen
  {
    position: fixed;
    width: 400px;
    right: 20px;
    background-color:  rgb(114, 205, 114);
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    z-index: 100;
    transition: bottom 0.3s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: 0.6s;
    display: none;
  }

  .pricefilterclose{
    position: fixed;
    width: 80px;
    height: 80px;
    right: 20px;
    background-color: rgba(114, 205, 114, 0.258);
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    z-index: 100;
    transition: bottom 0.3s ease-in-out;
    display: flex;
    align-items: center;
    transition: 0.6s;
    display: block;
  }

  .pricefilteropen i{
    cursor: pointer;
  }

  .pricefilteropen input{
    width: 45%;
  }

  .inputmax{
    margin-left: 10px;
  }

  .pricefilterclose:hover{
    background-color:  rgba(114, 205, 114, 0.814);
  }

 

  .pricefilterclose h4{
    margin: auto;
    margin-top: 15px;
    
  }

  .containerpag {
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .containerpag .pagination {
    position: relative;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    border-radius: 2px;
  }
  .containerpag .pagination li {
    list-style-type: none;
    display: inline-block;
    cursor: pointer;
  }
  .containerpag .pagination li h5 {
    position: relative;
    padding: 20px 25px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
  }
  .containerpag .pagination li h5:hover,
  .containerpag .pagination li.active h5 {
    background: rgba(255, 255, 255, 0.2);
  }
  

.ahrefgo{
    text-decoration: none;
    color: black;
}

.ttt{
    width: 30%;
    height: 30px;
    background-color: black;
}

.cardswatch{
    width: 20%;
    background-color: white;    
    display: inline-block;
    margin-top: 5%;
    border:1px;
    margin-left: 1px;
    position: relative;
}

.cardswatch:hover{
    border-style: groove;
    border-color: black;
    box-shadow: 8px 10px 5px 3px rgba(51, 49, 49, 0.332);
}

.fa-shapr {
    position: absolute;
    margin-left: -98%;
    font-size: 120%;
    cursor: pointer;
}

/* .fa-solid{
    position: absolute;
    margin-left: -98%;
    font-size: 120%;
    display: none;
    cursor: pointer;
} */


.cardswatchimg{
    width: 100%;
    position: relative;
    height: 80%;
}

.cardswatchimg img{
    width: 100%;
    height: 100%;
    border-radius: 3%;
}

.cardslorem{
    width: 100%;
    height: 20%;
    display: flex;
}



.cardsh h6{
    width: 30%;
    margin-top: -3%;
    font-size: 90%;
    color: rgb(79, 78, 78);
    margin-left: 1%;
}

.cardsh h4{
    font-size: 90%;
    margin-left: 1%;
}

.cardslorem div{
    width: 55%;
    margin-left: 3%;
    display: inline-block;
}

.buybutton {
    width: 30%;
    height: 12%;
    color: white;
    transition: 0.01s;
    margin-left: 68%;
    margin-top: -16.2%;
    background-color: black;
    text-decoration: none;
    position: absolute;
    text-align: center;
    padding-top: 3%;
    max-height: 12%;;
}

.buybutton:hover{
    background-color: white;
    color: black;
    box-shadow: 0px 0px 0px 1px rgb(0, 0, 0);
}

.cardsbutton{
    background-color: black;
    color: white;
}



.paginationdiv{
    margin-top: 5%;
    margin-left: 3%;
}

.pagination{
    margin: auto;
}

.pagination a{
    background-color: transparent;
    color: white;
    font-size: 130%;
    cursor: pointer;
}

.pagination a:hover{
    color: black;
}

.pagination li:hover{
    color: black;
}

.searcontainer{
    width: 90%;
    margin: auto;
    height: 5vh;
    margin-top: 2%;
    margin-left: 2.4%;
    display: flex;
    flex-wrap: wrap;
}

.searcontainer-2{
    width: 95%;
    margin: auto;
    margin-top: 2%;
    display: none;
}

.searchinput{
    width: 80%;
    height: 5vh;
    transition: 1s;
    background-color: whiete;
    border-color: transparent;
    user-select: none;
    border-radius: 4px;

}

.searchform2{
  width: 100%;
}

.searchinput-2{
    width: 100%!important;
    height: 5vh;
    transition: 1s;
    background-color: white;
    border-color: white;
    border-radius: 4px;
}




.fa-solid{
    font-size: 150%;
    user-select: none;  
}

@media(max-width:768px){
    .cardswatch{
        width: 55%;
        height: 340px;
        margin-top: 20px;
    }
    .cardscontainer{
        margin-top: 40px;
    }
    .searchinput-2{
        width: 85%;
    }
    .searcontainer-2{
        display: flex;
        flex-wrap: nowrap!important;
        justify-content: space-between;
      }
    .searcontainer{
        display: none;
    }
    .button-32{
      display: block!important;
      width: 100%;
    }
    .searchform2button{
      display: block;
    }
    .searchdiv2button{
      width: 30%;
    }
    .searchdiv2{
      width: 50%;
    }
    .app{
      width: 30%;

    }
    .overlay.show {
      opacity: 1;
      pointer-events: all;
    }
    .popup{
      width: 100%;
      height: 50%;
      margin: auto;
    }

  }