.carousel-item img{
    width: 100%;
    height: 100%;
}

.nonehh{
    display: none;
}

.carousel-item{
    height: 90%;
}



.carousel-indicators button{
    cursor: default!important;
}

#closei{
    display: none;
}



.greenwichnav{
    background-color:#d3d6b4ae;
    width: 100%;
    /* position: fixed; */
    display: flex;
    z-index: 999;
    top: 0px;
}

.greenwichnavimg{
    width: 35%;
    margin: auto;
}

.greenwichnavimg img{
    width: 100%;
   user-select: none;
   cursor: pointer;
   margin-left: 60%;
}

.dropulli ul li{
    transition: 0.5s;
    user-select: none!important;
    list-style-type: none;
    color: black;
}

.dropulli a{
    text-decoration: none;
    color: black;
}


.dropdowncontainer{
    width: 20%!important;
    margin-right: 1%!important;
    margin: auto;
    display: none;
}

.dropulli{
    position: fixed;
    background-color: #d3d6b4;
    width: 40%!important;
    margin-left: auto;
    margin-right: auto;
    transition: 0.5s;
    display: none;
    z-index: 100;
}

.dropdown{
    width: 10%;
    margin: auto;
    text-align: center;
}

.greenwichanvhac{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 40%;
    margin: auto;
}

.greenwichanvhac ul{
    display: flex;
}

.greenwichanvhac ul li{
    display: inline-block;
    transition: 0.5s;
    user-select: none;
}

.greenwichanvhac li:hover{
    transform: scale(1.3);
}


.greenwichanvhac a{
    text-decoration: none;
    color: black;
}



.smth-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
}

.smth-container div{
    width: 45%;
    height: 40vh;
    transition: 1s;
}

.smth-container div:hover{
    transform: scale(1.1);
}

.smth-container div img{
    width: 100%;
    height: 100%;
}

.carousel{
    position: relative;
    border-radius: 20px;
    width: 100%;
}


.carousel-container{
    width: 100%;
}

.greenwichanvhac li{
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Playfair Display', serif;
    font-size: 150%;
}

@media(max-width:768px){
    .carousel-container{
        margin-top: -100px;
    }

    .greenwichnavimg{
        margin-left: auto;
    }
    .greenwichnav div{
        margin: auto;
    }
    .carousel{
        height: inherit;
    }

    .smth-container div{
        width: 70%;
        height: inherit;
    }
    .smth-container div:hover{
        transform: none;
    }
    .greenwichnavimg{
        margin-left: -230px!important;
        width: 80%!important;
    }
    .greenwichanvhac{
        display: none;
    }

    .dropdowncontainer{
        display: block;
        margin-right: 13%!important;
    }
    .dropulli{
        width: 30%;
    }

}











