.admincontainer{
    width: 100%;
}

.adminrow{
    width: 100%;
    height: 62vh;
    display: flex;
    background-color: #d3d6b4e8;
    margin: auto;
    margin-top: 50px;
}
label{

    font-family: 'Kanit', sans-serif;
    margin-top: 20px;

}
.buyimg{
    width: 24%;
    height: 48vh;
    background-color: white;
    margin-left: 165px;
    margin-top: 50px;
    border-radius: 10px;

}

.number{
    margin-left: 150px;
    margin-top: 10px;
    width: 45%;
}


.number input{
    text-decoration: none;
    width: 30%;
    height: 50px;
    margin-top: 20px;
    border-radius: 10px;
    border: none!important;
    outline: none;
    padding-left: 10px;
}

.inputunvan{
    width: 100%!important;
    border: none!important;
    outline: none;
    margin-top: -20px;
    padding: 0px 10px;
}

.adminrow button{
    display: inline-block;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;  
      border: solid black 3px;
      border-radius: 10px;
      color: #fff;
      background-color: #000000; /* Цвет фона кнопки */
      transition: background-color 0.3s ease;
      margin-top: 20px;
}

.adminrow button:hover{
    background-color: #c7c7c7;
    color: #000000;
}

.button2{
    margin-left: 40px;

}
:root {
    --arrow-bg: rgba(165, 168, 120, 0.662);
    --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
    --option-bg: white;
    --select-bg: rgb(165 168 120);
  }
  * {
    box-sizing: border-box;
  }
  
  /* <select> styles */
  select {
    /* Reset */
    margin-top: 30px;
    appearance: none;
   
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 14rem;
    padding: 1rem 4rem 1rem 1rem;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
      linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
    color: rgba(0, 0, 0, 0.773);
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* Remove IE arrow */
    &::-ms-expand {
      display: none;
    }
    /* Remove focus outline */
    &:focus {
      outline: none;
      
    }
    /* <option> colors */
    option {
      color: black;
      background-color: var(--option-bg);
    }
  }
  .option-val{
    border-radius: 0;
    background-color:rgb(148, 151, 113) ;
    font-size: 16px;
    padding-top: 10px;
    font-weight: 500;
    border: none;
    outline: none;
  }
  