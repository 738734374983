*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.nonehh{
display: none;
}

.nav1{
    position: fixed;
}

.wrapper{
    display: inline-flex;
    list-style: none;
    margin-top: 20px;
}

.wrapper .icon{
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.5s cubic-bezier( 0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip{
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s cubic-bezier( 0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before{
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.5s cubic-bezier( 0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip{
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip{
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before{
    background:linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);
    color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before{
    background: #f09433; 
    color: #ffffff;
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}


.carousel{
    width: 80%;
    display: flex;
    position: absolute;
    margin-top: 190px;
  
}

.scroll-to-top-button {
    position: fixed;
    bottom: -80px;
    width: 80px;
    height: 80px;
    right: 20px;
    background-color: rgba(114, 205, 114, 0.258);
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
    transition: bottom 0.3s ease-in-out;
    display: flex;
    align-items: center;
    transition: 0.6s;
  }
  
  .scroll-to-top-button i{
    font-size: 40px;
    margin: auto;
    transition: 0.6s;
    color: rgba(31, 163, 31, 0.311);
  }

  .scroll-to-top-button i:hover{
    color:  rgb(31, 163, 31);
   

}

  .scroll-to-top-button:hover{
    background-color:  rgba(114, 205, 114, 0.814);
    rotate: 360deg;
  }
  
  .scroll-to-top-button.visible {
    bottom: 20px; 
  }

#collg2{
    display: none;
}

.carousel-inner img{
    width: 100%;
}

.navwatch{
    /* background-color: rgba(146, 146, 146, 0.727); */
    /* background-color: rgba(8, 46, 8, 0.603); */
    background-color:#7eb77f7a;
    position: relative;
}

.nonediv{
    height: 100px;
}

.navwatch ul{
    list-style: none;
    display: flex;
    width: 70%;
    justify-content: space-around;
}

.btn{
    border-radius: 0px;
    width: 50%;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.142);
    color: black;

}

.btn:hover{
    background-color: transparent;
    border-color: transparent;
    color: black;
}

.navwatch2{
    width: 100%;
    margin: auto;
    background-color: #7eb77f7a;
    display: none;
}



.allgenderlink{
    margin: auto;
    display: inline-block;
    transition: 0.4s;
    z-index: 1;
    text-decoration: none;
    color: black;
    list-style: none;
}

.allgenderlink:hover{
    transform: scale(1.2);
}

.ullidiv{
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    top: 0;
    padding: 20px 0px;
  
}

.watchesname-kishi{
    width: 75%;
    background-color:#d3d6b4;
    /* background-color: rgb(203, 211, 189); */
   display: none;
   margin-top: -20px;
   max-height: 115vh;
   margin-left: 8.7vw;
   position: absolute;
   z-index: 100!important;
    border-radius: 10px;
    display: flex;

}



.watchesname-qadin{
    width: 75%;
    background-color:#d3d6b4;
    /* background-color:rgb(203, 211, 189); */
    margin-top: -20px;
   margin-left: 8vw;
   display: none;
   position: absolute;
   z-index: 10!important;
   border-radius: 10px;
    max-height: 115vh;
}

.menwatch{
    color: black;
    font-size: 130%;
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Playfair Display', serif;
    
}

.womenwatch{
    font-size: 130%;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Playfair Display', serif;
    
}

.childwatch{
    font-size: 130%;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    font-family: 'Playfair Display', serif;
}

.jewerelly{
    font-size: 130%;
        color: black;
        font-family: 'Josefin Sans', sans-serif;
        font-family: 'Playfair Display', serif;
}

.watchesname-ushaq{
    width: 75%;
    height: 65vh;
    background-color:#d3d6b4;
    /* background-color:rgb(203, 211, 189); */
    margin-top: -20px;
   margin-left: 6.5vw;
   display: none;
   position: absolute;
   z-index: 1!important;
   border-radius: 10px;
    
}

.watchesname-aksesuar{
    width: 75%;
    height: 65vh;
    background-color:#d3d6b4;
    /* background-color:rgb(203, 211, 189); */
    margin-top: -20px;
    margin-left: 5vw;
   display: none;
   position: absolute;
   z-index: 1!important;
   border-radius: 10px;
    
}



.bg-dark{
    background-color: rgba(255, 255, 255, 0.505)!important;
}



.mb-4{
    height: 15vh;
}

.bigfooter{
    width: 100%;
    background-color: #d3d6b4ae!important;
    color: black;
    flex: 0 0 auto;
    transition: 1s;
    margin-top: 5%;
    overflow: hidden;
}

.watchesname-kishi i{
    position: absolute;
    top: -20px;
    left: 4.3%;
    color: #d3d6b4;
    font-size: 40px;
}

.watchesname-qadin i{
    position: absolute;
    top: -20px;
    left: 17%;
    color: #d3d6b4;
    font-size: 40px;
}

.watchesname-ushaq i{
    position: absolute;
    top: -20px;
    left: 30%;
    color:#d3d6b4;
    font-size: 40px;
}

.watchesname-aksesuar i{
    position: absolute;
    top: -20px;
    left: 42.5%;
    color:#d3d6b4;
    font-size: 40px;
}

.trendkishi{
    margin-left: 6%;
    margin-top: 1.5%!important;
    width: 20%;
    display: inline-block;
}

.brdiv{
    display: none;
}

.trendkishi h6{
 color: black;
}

.trendkishi{
    margin-left: 6%;
    margin-top: 1.5%!important;
    width: 20%;
    display: inline-block;
}

.trendkishiU{
    margin-left: 6%;
    width: 20%;
    height: 55%;
    margin-top: 1.5%!important;
    display: inline-block;
}


.trendxett {
    width: 78%;
    height: 1.5px;
    background-color: black;
    margin-left: -1%;
}

.trendh6{
    width: 100%;
    
}



.h16{
    margin-top: 10%;
    color: rgb(0, 37, 0);
}

.h66{
    margin-top: -1%;
    color: rgb(0, 37, 0);
}

.h66:hover{ 
    color: rgb(61, 58, 58);
}

.trendh6 a{
    cursor: pointer;
    text-decoration: none;
    color: rgb(70, 69, 69);
}

.trendh6 a:hover{
    color:  rgb(61, 58, 58);
    text-decoration: underline;
}

.trendh6U a{
    cursor: pointer;
    text-decoration: none;
    color: rgb(70, 69, 69);
}

.trendimageK{
    width: 25%;
    height: 35vh;
    display: inline-block;
    overflow: hidden;
    margin: auto;
    top: -33vh;
    margin-left: 13%;
   position: relative;
   border-radius: 10px;
}



.trendimageQ{
    width: 30%;
    height: 40vh;
    display: inline-block;
    overflow: hidden;
    margin-left: 10%;
    top: -43vh;
   position: relative;
   border-radius: 10px;
}

.trendimageU{
    width: 25%;
    height: 35vh;
    display: inline-block;
    overflow: hidden;
    margin: auto;
    left: 15%;
    top: -5%;
   position: relative;
   border-radius: 10px;
   transition: 1s;
   gap: 10px;
}


.trendimageJ{
    width: 25%;
    height: 35vh;
    display: inline-block;
    overflow: hidden;
    margin: auto;
    margin-left: 13%;
    border-radius: 10px;
   position: relative;
}



.trendimageK img{
    width: 100%;
    height: 100%;
}

.trendimageQ img{
    width: 100%;
    height: 100%;
}

.trendimageU img{
    width:  100%;
    height: 100%;
}

.trendimageJ  img{
    width: 100%;
    height: 100%;
}

.logopage{
    width: 25%;
    height: 25vh;
    display: inline-block;
    top: -47vh;
    position: relative;
    margin-left: 4%;
}

.logopageQ{
    width: 25%;
    height: 25vh;
    display: inline-block;
    top: -60vh;
    position: relative;
    margin-left: 4%;
}

.logopageJ{
    width: 25%;
    height: 53.9%;
    display: inline-block;
    position: relative;
    border-radius: 10px;
    margin-left: 2%;
    top: -15vh;
}

.logopageU{
    width: 25%;
    height: 44%;
    margin-top: -22%;
    margin-left: 70%;
    border-radius: 10px;
}

.logopageU img{
    width: 100%;
    height: 100%;
}

.logopageJ img{
    width: 100%;
    height: 100%;
}

.logopage img{
    width: 100%;
    height: 100%;
}

.logopageQ img{
    width: 100%;
    height: 100%;
}

.scroller{
    width: 5%;
    right: 2%;
    height: 10%;
    position: absolute!important;
    z-index: 15;
    position: fixed!important;
    border-radius: 80%;
    top: 80%;
    background-color:rgba(114, 205, 114, 0.258);
    text-align: center;
    transition: 1s;
}

.scroller:hover{
    background-color:  rgba(114, 205, 114, 0.814);
}

.scroller i{
    font-size: 250%;
    color: rgba(31, 163, 31, 0.311);
    transition: 1s;
    margin-top: 20%;
}

.scroller i:hover{
    color:  rgb(31, 163, 31);

}


@media(max-width:768px){
    .navwatch{
        display: none;
    }
    .navwatch2{
        display: block;
    }
    .offcanvas-body a{
        text-decoration: none; /* Убираем стандартное подчеркивание ссылок */
        color: black; /* Устанавливаем цвет текста (в данном случае, черный) */
    }
    .bigfooter{
        margin-top: 5%;
    }
    .scroller{
        display: none;
    }
    .trendimageK{
        display: none;
    }
    .trendimageQ{
        display: none;
    }
    .trendimageU{
        display: none;
    }
    .trendimageJ{
        display: none;
    }
    .logopage{
        display: none;
    }
    .logopageQ{
        display: none;
    }
    .logopageU{
        display: none;
    }
    .logopageJ{
        display: none;
    }
    .watchesname-kishi{
        margin-left: 5%;
        z-index: 50!important;
        padding-top: 10px;
    }
    .trendkishi{
        margin-left: 20%;
        width: 100%;

    }
    .trendkishiU{
        margin-left: 20%;
        width: 100%;
    }
    .trendxett{
        width: 61%!important;
    }
    .watchesname-qadin{
        margin-left: 15%;
        z-index: 50!important;
        padding-top: 10px;
    }
    .watchesname-qadin .fa-solid{
        margin-left: 10%;
    }
    .watchesname-ushaq{
        margin-left: 15%;
        z-index: 50!important;
        padding-top: 10px;
    }
    .watchesname-ushaq .fa-solid{
        margin-left: 30%;
    }
    .watchesname-aksesuar{
        margin-left: 20%;
        z-index: 50!important;
        padding-top: 10px;
    }
    .watchesname-aksesuar .fa-solid{
        margin-left: 40%;
    }
    .col-lg-3 {
        margin-top: 100px;
    }
    .row{
        margin-top: -130px;
    }
    #collg1{
        display: none;
    }
    #collg3{
        display: none;
    }
    #collg4{
        display: none;
    }
    #collg2{
        display: block;
    }
    #collg2 a{
        color: black;
    }
    .brdiv{
        display: block;
    }
    .wrapper{
        padding-bottom: 30px!important;
    }
}









