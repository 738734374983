.btnn{
    width: 25%;
    height: 30%;
    transition: 0.1s;
    margin-left: 30px;
    background-color: transparent;
    margin-top: 25px;
    border-radius: 13px;
    margin-left: 90px;
     border-color: transparent;
    transition: 0.5s;
}

.btnn:hover{
    transform: scale(1.1);
}

.btnn:hover .hoverr{
    /* background-color: rgba(6, 156, 21, 0.77); */
        background-color: rgba(114, 205, 114, 0.814);
        color: white;
        width: 100%;
        height: 100%!important;

}

.btnn:hover .hoverr h6{
    color: white;
}

.hoverr{
    width: 0%;
    height: 0%!important;
    top: -100%;
    position: relative;
    border-radius: 10px!important;
    background-color: transparent;
    text-align: center;
    transition: 0.5s;
}

.hoverr h6{
    padding-top: 32%;
    font-size: 220%;
    color: transparent;
    transition: 1s;
}

/* .hoverr:hover{
       
}    */

.btnn div{
    width: 100%;
    height: 100%;
    border-radius: 2px;
}

.btnn div img{
    width: 100%;
    height: 100%;
    border-radius: 10px;

}

.descrcontainer{
    width: 100%;
    height: 130vh;
}

.descrcontainer a{
    color: black;
    text-decoration: none;
}

.greenwichaboutcontainer{
    width: 100%;
    display: flex;
}

.greenwichaboutdescr{
    width: 45%;
    height: 50vh;
    border-radius: 2%;
    margin-top: 5%;
    margin-left: 1.5%;
    transition: 1s;
    display: inline-block;
}

.greenwichaboutdescr:hover{
    background-color: beige;
    transform: scale(1.1);
}





.greenwichaboutdescr div{
    padding-top: 20%;
    margin-left: 13%;
    width: 75%;
}

.greenwichaboutdescr2{
    width: 20%;
    /* height: 50vh; */
    margin-left: 3%;
    margin-top: 3%;
    margin-top: 25%;
    transition: 1s;
    border-radius: 2%;
}

.greenwichaboutdescr2:hover{
    background-color: rgb(235, 231, 231);
    transform: scale(1.1);
}

.greenwichaboutdescr2 div{
    width: 70%;
    margin: auto;
    margin-top: 3%;
}

.greenwichaboutcontainer h5 {
    font-family: 'EB Garamond', serif;
    
}

.greenwichaboutcontainer h2 {
    font-family: 'EB Garamond', serif;
    
}

.greenwichaboutdescr3{
    width: 20%;
    height: 50vh;
    margin-left: 5%;
    margin-top: 2%;
    transition: 1s;
    border-radius: 2%;
}

.greenwichaboutdescr3:hover{
    background-color: rgb(219, 186, 186);
    transform: scale(1.1);
}

.greenwichaboutdescr3 div{
    width: 70%;
    margin: auto;
    margin-top: 5%;
    margin-left: 25%;
}

.greenwichaboutdescr3 h5{
    margin-top: 10%;
}

.iframe{
    width: 98%;
    height: 60%;
    border-radius: 3%;
}

.mall-28 img{
    width: 100%;
    height: 100%;
}

@media(max-width:768px){
    .descrcontainer{
        height: auto;
    }
    .descrcontainer a{
        color: black;
        text-decoration: dashed!important;
    }
    .btnn:hover{
        transform: none ;
    }

    .hoverr{
        display: none;
    }
    .btnn{
        width: 60%;
        height: 20%;
        margin-left: 20%;
    }
    .greenwichaboutcontainer{
        width: 100%!important;
        display: flex;
        flex-wrap: wrap;
    }
    .greenwichaboutdescr{
        width: 100%!important;
        height: auto;
    }
    .greenwichaboutdescr div{
        padding-top: 10px;  
    }
    .greenwichaboutdescr2{
        width: 100%!important;
        height: auto;
    }
    .greenwichaboutdescr3{
        width: 100%!important;
        height: auto;
    }
    .greenwichaboutdescr:hover{
        transform: none;
        background-color: transparent;
    }
    .greenwichaboutdescr2:hover{
        transform: none;
        background-color: transparent;
    }
    .greenwichaboutdescr3:hover{
        transform: none;
        background-color: transparent;
    }
}


